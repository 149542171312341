<template>
  <div class="login">
    <el-card>
      <h2>{{ $t("loginPageCardTitle") }}</h2>
      <el-form
        class="login-form"
        :model="model"
        :rules="rules"
        ref="loginForm"
        @submit.native.prevent="login"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="model.phone"
            :placeholder="$t('loginPageFirstField')"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="model.password"
            :placeholder="$t('loginPageSecondField')"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loggingIn"
            class="login-button"
            type="primary"
            native-type="submit"
            block
          >
            {{ $t("loginPageButtonLabel") }}
          </el-button></el-form-item
        >
        <el-form-item>
          <div class="login_button_container">
            <div id="google-btn"></div>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  data() {
    return {
      loggingIn: false,
      googleLoaded: false,
      model: {
        phone: "",
        password: "",
      },
      loading: false,
      rules: {
        phone: [
          {
            required: true,
            message: this.$t("loginPageValidationRuleFirstFieldError"),
            trigger: "blur",
          },
          {
            min: 10,
            message: this.$t("loginPageValidationRuleFirstField"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("loginPageValidationRuleSecondFieldError"),
            trigger: "blur",
          },
          {
            min: 5,
            message: this.$t("loginPageValidationRuleSecondField"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    try {
      google.accounts.id.initialize({
        client_id: this.googleClientId,
        callback: this.onSignIn,
      });
      google.accounts.id.prompt();
      const btnParent = document.getElementById("google-btn");
      google.accounts.id.renderButton(btnParent, {
        type: "standard",
        size: "large",
      });
    } catch (err) {
      window.onload = () => {
        google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: this.onSignIn,
        });
        google.accounts.id.prompt();
        const btnParent = document.getElementById("google-btn");
        google.accounts.id.renderButton(btnParent, {
          type: "standard",
          size: "large",
        });
      };
    }
  },
  computed: {
    googleClientId: function () {
      return process.env.VUE_APP_GOOGLE_CLIENT_ID;
    },
  },
  methods: {
    async onSignIn(googleUser) {
      try {
        this.$loading();
        await this.loginSocial({
          credential: {
            strategyOptions: { googleToken: googleUser.credential },
            strategy: "google",
            device: "",
            agentType: "",
          },
        });
      } catch (err) {
        this.$notify({
          title: "Error",
          message: err,
          type: "error",
        });
      } finally {
        this.$loading().close();
      }
    },
    ...mapActions({
      authLogin: "Authentication/login",
      loginSocial: "Authentication/loginSocial",
    }),

    //*************************************** */
    //Form Area
    //*************************************** */
    handleSubmit() {
      this.submitted = true;
      const { phone, password } = this.model;
      if (phone && password) {
        return this.authLogin({ phone, password });
      }
    },
    async login() {
      let valid = await this.$refs.loginForm.validate();
      if (!valid) {
        return;
      }

      this.loggingIn = true;

      this.handleSubmit()
        .then(
          () => {
            this.$message.success(this.$t("loginPageLoginSuccessMessage"));
          },
          () => {
            this.$message.error(this.$t("loginPageLoginErrorMessage"));
          }
        )
        .finally(() => {
          this.loggingIn = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login_button_container {
  margin-top: 40px;

  .login-button {
    width: 100%;
  }
}
.login-form {
  width: 290px;
}
h2 {
  font-family: "Open Sans";
  letter-spacing: 1px;
  font-family: Roboto, sans-serif;
  padding-bottom: 20px;
}
.login .el-card {
  width: 340px;
  display: flex;
  justify-content: center;
}

[dir="rtl"] .el-form-item ::v-deep .el-form-item__content {
  margin-left: 0;
  margin-right: 0;
}
</style>
